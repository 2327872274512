<template>
  <v-app-bar 
    app
    flat
    color="white"
    v-resize="onResize"
    :value="displayView"
  >
    <v-slide-y-reverse-transition mode="out-in">
      <v-btn 
        icon
      >
        <icon-base
          width="20"
          height="24"
          icon-name="hive"
          class="hive"
        >
          <brand-symbol />
        </icon-base>
      </v-btn>
    </v-slide-y-reverse-transition>
    <!-- <v-expand-x-transition mode="out-in">
      <icon-base
        v-if="breakpoint('sm')"
        width="126"
        height="26"
        icon-name="Mobees"
        class="logo ml-1 mr-8"
      >
        <brand-logo />
      </icon-base>
    </v-expand-x-transition> -->

    <v-tabs
      color="primary"
      background-color="transparent"
      active-class="font-weight-black"
      class="tabs ml-1 scrollable x"
    >
      <v-tab
        v-for="(tab, key) in tabs"
        :key="'tab-'+key"
        :to="{ 'name': tab.path.name, params: getParams(tab.path.params) }"
        :disabled="tab.disabled"
      >
        <span v-if="breakpoint('xs')">
          {{ tab.title }}
        </span>
        <v-icon 
          v-else
          class=""
        >
          {{ icons[key] }}
        </v-icon>
      </v-tab>
    </v-tabs>

    <v-spacer />

    <notification-center
      :user="user"
    />

    <v-menu
      transition="slide-y-transition"
      offset-y
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          small
          icon
          v-on="on"
        >
          <v-icon v-text="icons.mdiDotsVertical" />
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="getout"
        >
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<style>

  #app .v-app-bar {
    /* box-shadow: 0px 4px 4px -11px rgba(0, 0, 0, 0.2), 0px 5px 5px -10px rgba(0, 0, 0, 0.14), 0px 6px 10px -6px rgba(0, 0, 0, 0.12); */
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    z-index: 10;
  }
  @media (orientation: landscape) {
    .v-app-bar.mobile-only {
      transform: translateY(-100%) !important;
      /* display: none; */
    }
  }
  @media (orientation: portrait) {
    .v-app-bar.mobile-only {
      transform: translateY(0) !important;
      /* display: block; */
    }
  }

  .v-app-bar .tabs {
    max-width: calc(100vw - 160px);
  }

  .v-app-bar .v-slide-group__prev {
    display: none !important;
  }

  .v-app-bar .v-tab {
    min-width: auto;
  }

  .v-toolbar__content {
    max-height: 120px;
    width: 100%;
  }
  /* .v-app-bar {
    background: var(--mobees-black) !important;
    transform: translateY(calc(16px - 100%)) !important;

  }
  .v-app-bar.toggle {
    transform: translateY(0) !important;
  } */

  .app-title {
    max-width: 72%;
    z-index: 1;
    transition: transform .15s ease-out;
    will-change: transform;
    transform: 0;
  }
  .app-title.extended {
    max-width: 80%;
    transform: translateX(-48px);
  }

  .v-app-bar .hive {
    width: 24px;
    color: var(--mobees-black);
    /* animation: buzz 5s 2s cubic-bezier(0.990, 0.005, 0.100, 1.005) infinite; */
  }

  @-webkit-keyframes buzz {
    0%{ 
      color: var(--mobees-black);
      filter: drop-shadow(4px 0px 0px rgba(rgb(71, 108, 255), 0)); 
    }
    25%{ 
      color: var(--mobees-black);
      filter: drop-shadow(-4px 0px 4px rgba(rgb(71, 108, 255), 0.5)); 
    }
    50%{ 
      color: rgb(71, 108, 255); 
      filter: drop-shadow(0px 4px 4px rgba(rgb(71, 108, 255), 1)); 
    }
    75%{ 
      color: var(--mobees-black);
      filter: drop-shadow(-4px 0px 4px rgba(rgb(71, 108, 255), 0.5)); 
    }
    100% { 
      color: var(--mobees-black);
      filter: drop-shadow(4px 0px 0px rgba(rgb(71, 108, 255), 0)); 
    }
  }


  .v-app-bar .logo {
    max-height: 26px;
    -webkit-filter: invert(72%);
    filter: invert(72%);
  }

  .v-app-bar .logo, .v-app-bar .hive {
    opacity: .8;
  }
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 8px;
  }

  .v-app-bar .btn-new {
    top: 84px;
    left: 274px;
  }

</style>

<script>
  // Utilities
  import services from '@/services'
  import { get, sync } from 'vuex-pathify'
  import device from 'mobile-device-detect';

  // Icons
  import { 
    mdiMagnify, mdiPlus, mdiDotsVertical,
    mdiMapMarkerMultipleOutline,
    mdiAccountOutline,
    mdiMenuDown,
    mdiStarOutline,
    mdiSaleOutline,
    mdiCheckAll,
    mdiFaceAgent
  } from '@mdi/js';

  export default {
    name: 'TopBar',

    props: {
      brands: {
        type: Array,
        default: () => []
      },
      user: {
        type: Object,
        default: () => null
      },
      admin: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        menu: mdiMenuDown,
        mdiMagnify, mdiPlus, mdiDotsVertical,
        buzzers: mdiMapMarkerMultipleOutline,
        drivers: mdiAccountOutline,
        flashmobs: mdiStarOutline,
        club: mdiSaleOutline,
        messaging: mdiCheckAll,
        support: mdiFaceAgent
      },
      view: {
        tabs: {
          buzzers: {
            title: 'Buzzers',
            disabled: false,
            roles: [1,2],
            path: {
              name: 'Buzzers',
              params: {}
            }
          },
          drivers: {
            title: 'Motoristas',
            disabled: false,
            roles: [1,2],
            path: {
              name: 'Motoristas',
              params: {}
            }
          },
          flashmobs: {
            title: 'Flashmobs',
            disabled: false,
            roles: [1,2],
            path: {
              name: 'Flashmobs',
              params: {}
            }
          },
          club: {
            title: 'Clube',
            disabled: false,
            roles: [1,2],
            path: {
              name: 'Clube',
              params: {}
            }
          },
          support: {
            title: 'Atendimento',
            disabled: false,
            roles: [1,2],
            path: {
              name: 'Atendimento',
            }
          },
          messaging: {
            title: 'Comunicações',
            disabled: false,
            roles: [1,2],
            path: {
              name: 'Comunicações',
              params: {}
            }
          },
        },
        history: 0,
        scroll: 0,
        display: true,
        landscape: false,
        mobile: false,
        updated: false,
        loading: false,
      },
      avatar: '',
      fab: {
        toggle: false,
        text: '',
        to: null
      },
      bar: {
        timeout: null,
      },
      logos: {}
    }),

    components: {
      IconBase: () => import('@/components/IconBase'),
      BrandSymbol: () => import('@/components/icons/BrandSymbol'),
      NotificationCenter: () => import('@/components/NotificationCenter'),
    },

    computed: {
      views: sync('app/views'),
      loading: sync('app/views@loading'),
      main: sync('app/views@main'),
      redirect: sync('app/redirect'),
      toast: sync('app/toast'),


      tabs () {
        const roles = this.user.roles;
        const tabs = _.pickBy(this.view.tabs, t => !_.has(t, 'roles') || _.size(_.intersection(t.roles, roles))>0);
        return _.mapValues(tabs, t => {
          t.disabled = _.has(t, 'disabled') && (t.disabled==true || (_.isArray(t.roles)&&_.size(_.intersection(t.roles, roles))==0));
          return t;
        });
      },

      minScroll () {
        return this.view.scroll < 24;
      },

      loggedIn () {
        return !_.isEmpty(this.brands);
      },

      displayView () {
        // console.log('display update', this.view.mobile, this.view.landscape);
        return !(this.view.mobile && this.view.landscape);
      },
    },

    watch: {
      $route: {
        immediate: true,
        handler (to, from) {
          // this.updateView();
        }
      },
    },

    filters: {
      initials (user) {
        return !_.isNil(user) && !_.isNil(user.username) ? user.username.substring(0,2) : '';
      },
    },

    methods: {
      ...services,

      getParams (params) {
        return _.reduce(params, (params, p) => {
          return {
            [p]: this.$route.params[p],
          }
        }, {});
      },

      // updateView (route) {
      //   if (this.loggedIn) {
      //     }
      //   }
      // },

      onScroll (e) {
        this.view.scroll = e.target.scrollingElement.scrollTop;
      },

      navigateBack () {
        let path = this.$route.path.split('/');
        path.pop();
        if (_.indexOf(path, 'campaigns')>=0 && path.length==3) path.pop();
        this.$router.push(path.join('/'));
      },

      switchWindow (target) {
        this.$router.push('/' + target.value);
      },

      onResize () {
        this.view.width = window.innerWidth;
        this.view.height = window.innerHeight;
        this.view.landscape = this.view.width > this.view.height;
        // console.log('display update', this.view.mobile, this.view.landscape);
      },

      // setDictionary () {
      //   _.each(this.view.tabs, (tab,k) => {
      //     this.view.tabs[k].title = this.getDictionary(k)||tab.title;
      //   });
      // }
    },

    mounted () {
      // this.setDictionary();
      window.addEventListener('scroll', this.onScroll);
      this.view.mobile = device.isMobileOnly;
    },
    destroyed () {
      window.removeEventListener('scroll', this.onScroll);
    },
  }
</script>
